.component-container {
  width: 100%;
  height: 252px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}
.component-image {
  width: 487px;
  height: 160px;
  object-fit: cover;
}
.component-text {
  color: #ffffff;
}
.component-text1 {
  color: #ffffff;
}


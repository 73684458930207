.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-navbar {
  width: 1713px;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
  background-color: #000000;
}
.home-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-heading {
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-container02 {
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-text {
  color: #fdfdfd;
}
.home-links-container {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-tripleunit);
}
.home-link01 {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-tripleunit);
}
.home-link02 {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-tripleunit);
}
.home-link03 {
  color: var(--dl-color-gray-white);
}
.home-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-link04 {
  display: contents;
}
.home-image {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
.home-link05 {
  color: var(--dl-color-gray-700);
  text-decoration: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-tripleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link06 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-link07 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-link08 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-container05 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f1f1;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #f1f1f1;
}
.home-hero-text-container {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading2 {
  color: #040404;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text01 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cta-btn-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  display: none;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: #c9c9c9;
}
.home-text02 {
  color: #101010;
}
.home-cta-btn1 {
  color: var(--dl-color-gray-black);
  display: none;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-white);
}
.home-image1 {
  width: 40%;
  object-fit: cover;
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text03 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text04 {
  text-align: center;
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-card {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-icon02 {
  width: 150px;
  height: 150px;
}
.home-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text05 {
  text-align: center;
}
.home-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-icon04 {
  width: 150px;
  height: 150px;
}
.home-content-container1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text06 {
  text-align: center;
}
.home-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-icon06 {
  width: 150px;
  height: 150px;
}
.home-content-container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading5 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text07 {
  text-align: center;
}
.home-about {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-max-content-width-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-container06 {
  flex: 0 0 auto;
  width: 390px;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text09 {
  width: 185px;
  align-self: flex-end;
  text-align: center;
}
.home-text10 {
  width: 159px;
  align-self: flex-end;
}
.home-content-container3 {
  width: 733px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: center;
}
.home-about-1 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-900);
}
.home-container07 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon08 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text11 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container08 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text18 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container09 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon12 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text22 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-about-11 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-900);
}
.home-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container11 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon14 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text27 {
  text-align: left;
}
.home-text30 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container12 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon16 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text33 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container13 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon18 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text34 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container14 {
  flex: 0 0 auto;
  width: 390px;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text37 {
  width: 185px;
  align-self: flex-end;
  text-align: center;
}
.home-text38 {
  width: 159px;
  align-self: flex-end;
}
.home-content-container4 {
  width: 733px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: center;
}
.home-about-12 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-900);
}
.home-container15 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon20 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text39 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container16 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon22 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text42 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container17 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon24 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text43 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-about-13 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-900);
}
.home-container18 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container19 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon26 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text46 {
  text-align: left;
}
.home-text49 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container20 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon28 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text52 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container21 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon30 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text53 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-container22 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.home-icon32 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text56 {
  color: var(--dl-color-gray-black);
  text-align: left;
}
.home-button {
  color: var(--dl-color-gray-white);
  display: none;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-black);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-clients {
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container2 {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text61 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text62 {
  text-align: center;
}
.home-logo-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  width: 150px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo1 {
  width: 150px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo2 {
  width: 150px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo3 {
  width: 150px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-logo4 {
  width: 150px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-pricing {
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container3 {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text63 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text64 {
  text-align: center;
}
.home-pricing-card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-card3 {
  width: 30%;
  display: flex;
  min-width: 350px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-card-heading {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-type {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-card-content {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-feature {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-limit {
  color: var(--dl-color-gray-700);
}
.home-feature1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-limit1 {
  color: var(--dl-color-gray-700);
}
.home-feature2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-limit2 {
  color: var(--dl-color-gray-700);
}
.home-feature3 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-limit3 {
  color: var(--dl-color-gray-700);
}
.home-choose {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-black);
}
.home-card4 {
  width: 30%;
  display: flex;
  min-width: 350px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-card-heading1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-type1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-card-content1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-container23 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text66 {
  color: var(--dl-color-gray-700);
}
.home-container24 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text68 {
  color: var(--dl-color-gray-700);
}
.home-container25 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text70 {
  color: var(--dl-color-gray-700);
}
.home-container26 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-text72 {
  color: var(--dl-color-gray-700);
}
.home-button1 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-black);
}
.home-card5 {
  width: 30%;
  display: flex;
  min-width: 350px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-card-heading2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-type2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-card-content2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-container27 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text74 {
  color: var(--dl-color-gray-700);
}
.home-container28 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text76 {
  color: var(--dl-color-gray-700);
}
.home-container29 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-text78 {
  color: var(--dl-color-gray-700);
}
.home-container30 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-text80 {
  color: var(--dl-color-gray-700);
}
.home-button2 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-black);
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator4 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-faqs {
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-text81 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-content-container5 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-faq {
  width: 85%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-question-container {
  width: 100%;
  align-self: center;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-question {
  font-size: 18px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}
.home-answer-container {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-answer {
  margin-bottom: var(--dl-space-space-unit);
}
.home-faq1 {
  width: 85%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-question-container1 {
  width: 100%;
  align-self: center;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-question1 {
  font-size: 18px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}
.home-answer-container1 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-answer2 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-faq2 {
  width: 85%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-question-container2 {
  width: 100%;
  align-self: center;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-question2 {
  font-size: 18px;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}
.home-answer-container2 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-answer4 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-section-separator5 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-subscribe {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image2 {
  width: 50%;
  object-fit: cover;
}
.home-container31 {
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-heading-container4 {
  width: 803px;
  height: 461px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text82 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-link10 {
  align-self: center;
  text-decoration: none;
}
.home-form {
  width: 803px;
  height: 252px;
}
.home-textinput {
  width: 100%;
  margin-right: 11px;
}
.home-select {
  width: 100%;
  margin-right: 12px;
}
.home-textinput1 {
  color: var(--dl-color-gray-black);
  width: 100%;
  margin-right: 11px;
}
.home-textarea {
  width: 100%;
  margin-right: 10px;
}
.home-button3 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 43px;
  text-align: center;
  padding-right: 1px;
  padding-bottom: 0px;
  background-color: var(--dl-color-gray-black);
}
.home-section-separator6 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-social-links {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon34 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon36 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon38 {
  width: 24px;
  height: 24px;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon40 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 1600px) {
  .home-navbar {
    width: 100%;
  }
  .home-text {
    color: #fdfdfd;
  }
  .home-link05 {
    color: var(--dl-color-gray-700);
    text-decoration: none;
  }
  .home-heading-container4 {
    width: 623px;
  }
  .home-link10 {
    align-self: center;
    text-decoration: underline;
  }
  .home-form {
    width: 100%;
  }
  .home-textinput {
    width: 100%;
  }
  .home-button3 {
    width: 100%;
  }
  .home-footer {
    width: 1528px;
    align-self: stretch;
  }
  .home-link11 {
    align-self: center;
  }
}
@media(max-width: 1200px) {
  .home-text37 {
    width: 138px;
    padding-left: 0px;
  }
  .home-text38 {
    width: 160px;
  }
  .home-content-container4 {
    height: 340px;
  }
  .home-about-13 {
    height: 264px;
  }
  .home-icon32 {
    width: 3px;
    height: 56px;
  }
  .home-text56 {
    color: var(--dl-color-gray-black);
  }
  .home-heading-container4 {
    width: 434px;
    height: 622px;
  }
  .home-link10 {
    align-self: center;
  }
  .home-form {
    width: 100%;
    height: 434px;
    margin-top: 43px;
  }
  .home-textinput {
    width: 100%;
  }
  .home-select {
    width: 100%;
  }
  .home-textinput1 {
    width: 100%;
  }
  .home-textarea {
    width: 100%;
    height: 100px;
  }
  .home-button3 {
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: center;
    margin-right: 0px;
    background-color: rgb(0, 0, 0);
  }
  .home-footer {
    width: 100%;
    align-self: center;
  }
  .home-link11 {
    text-align: left;
  }
}
@media(max-width: 991px) {
  .home-navbar {
    height: 127px;
  }
  .home-heading {
    text-align: center;
  }
  .home-heading1 {
    text-align: center;
  }
  .home-hero {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-hero-text-container {
    width: 80%;
    align-items: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading2 {
    text-align: center;
  }
  .home-text01 {
    text-align: center;
  }
  .home-image1 {
    width: 100%;
  }
  .home-text03 {
    text-align: center;
  }
  .home-pricing-card-container {
    justify-content: center;
  }
  .home-card3 {
    margin-right: var(--dl-space-space-fourunits);
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-card4 {
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading-container4 {
    width: 308px;
    height: 676px;
    align-self: center;
  }
  .home-link10 {
    align-self: center;
  }
  .home-form {
    width: 100%;
    margin-right: var(--dl-space-space-tripleunit);
  }
  .home-textinput {
    width: 100%;
  }
  .home-footer {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-navbar {
    height: 136px;
    position: relative;
  }
  .home-links-container {
    display: none;
  }
  .home-link05 {
    font-size: 12px;
  }
  .home-cta-btn {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-cta-btn1 {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-heading-container {
    width: 100%;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-text08 {
    text-align: center;
  }
  .home-content-container3 {
    width: 686px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .home-about-1 {
    width: 239px;
  }
  .home-about-11 {
    width: 212px;
  }
  .home-content-container4 {
    width: 686px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .home-about-12 {
    width: 239px;
  }
  .home-about-13 {
    width: 212px;
  }
  .home-heading-container2 {
    width: 100%;
  }
  .home-heading-container3 {
    width: 100%;
  }
  .home-card3 {
    margin-right: 0px;
  }
  .home-content-container5 {
    width: 100%;
    flex-direction: column;
  }
  .home-faq {
    width: 100%;
  }
  .home-faq1 {
    width: 100%;
  }
  .home-faq2 {
    width: 100%;
  }
  .home-subscribe {
    flex-direction: column;
  }
  .home-image2 {
    width: 70%;
  }
  .home-container31 {
    width: 100%;
    align-items: center;
  }
  .home-heading-container4 {
    width: 100%;
  }
  .home-text82 {
    text-align: center;
  }
  .home-text83 {
    text-align: center;
  }
  .home-text84 {
    text-align: center;
  }
  .home-link10 {
    align-self: center;
  }
  .home-footer {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-hero-text-container {
    width: 100%;
  }
  .home-card {
    width: 100%;
  }
  .home-card1 {
    width: 100%;
  }
  .home-card2 {
    width: 100%;
  }
  .home-about-1 {
    width: 206px;
  }
  .home-about-11 {
    width: 220px;
    height: 315px;
  }
  .home-about-12 {
    width: 206px;
  }
  .home-about-13 {
    width: 220px;
    height: 315px;
  }
  .home-text49 {
    color: var(--dl-color-gray-black);
  }
  .home-text56 {
    color: var(--dl-color-gray-black);
  }
  .home-text61 {
    text-align: center;
  }
  .home-text63 {
    text-align: center;
  }
  .home-content-container5 {
    padding-left: 0px;
  }
  .home-image2 {
    width: 100%;
  }
  .home-container31 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-link10 {
    align-self: center;
  }
}
